import React, { useState } from "react";
import styled from "styled-components";
import { useTable, useSortBy } from "react-table";
import { format } from 'date-fns';
import InfiniteScroll from "react-infinite-scroll-component";
import { Box, Button, Checkbox, CircularProgress, Container, FormControlLabel, FormGroup, Stack, TextField, Typography } from "@mui/material";
import { setToken } from "../services/token";
import { getUrl } from "../auth/getUrl";
import { useNavigate } from "react-router-dom";



function Settings(props: any) {
  const navigate = useNavigate();


  const [isLoading, setIsLoading] = React.useState(false);


  var onChangeProtocClick = () => {

    setIsLoading(true)
    getUrl("/api/xray/reset_by_domain?domain="+domain)

      .then(res => {
        /*  props.refresh() */


        if (res.status == 401) {
          props.setToken(false)
          return;

        }

        setIsLoading(false)

        window.location.reload()
      }, error => {
        navigate("/login")
      })
  }

  const [domain, setDomain] = React.useState("");

  const handleDomain = (event: React.ChangeEvent<HTMLInputElement>) => {
    setDomain(event.target.value);
  };


  return (
    <div>
      {isLoading
        ? <Box sx={{ display: 'flex', justifyContent: 'center', pt: 2 }}>

          <CircularProgress sx={{ display: 'flex', justifyContent: 'center', color: '#1E88E5' }} />
        </Box>


        : <Box>
          <TextField
            margin="normal"
            required
            fullWidth
            onChange={handleDomain}
            name="domain"
            label="Domain"
            type="text"
            id="domain"
            autoComplete="current-password"
          />
          <Button onClick={onChangeProtocClick} color="primary" >Обновить лимиты</Button>
        </Box>
      }
    </div>
  );
}

export default Settings;
