import * as React from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Alert, Avatar, Box, Button, ButtonGroup, Checkbox, CircularProgress, Divider, FormControl, FormControlLabel, Grid, IconButton, InputBase, InputLabel, LinearProgress, LinearProgressProps, Link, MenuItem, Paper, Select, Skeleton, Stack, Switch, TextField, styled } from '@mui/material';
import Moment from 'moment';
/* import "./styles.css" */
import AddIcon from '@mui/icons-material/Add';
import { redirect, useLocation, useNavigate } from 'react-router-dom';
import { LoadingButton } from '@mui/lab';
import ComputerIcon from '@mui/icons-material/Computer';
import QrCodeIcon from '@mui/icons-material/QrCode';
import DownloadIcon from '@mui/icons-material/Download';

import { Edit } from '@mui/icons-material';
import LinkIcon from '@mui/icons-material/Link';

import OpenInNewIcon from '@mui/icons-material/OpenInNew';

import EditIcon from '@mui/icons-material/Edit';
import RefreshIcon from '@mui/icons-material/Refresh';
import DeleteIcon from '@mui/icons-material/Delete';
import { getUrl } from '../auth/getUrl';
import SearchIcon from '@mui/icons-material/Search';
import DirectionsIcon from '@mui/icons-material/Directions';
import AlertDialog from './AlertDialog';
import DeleteDialog from './DeleteDialog';
import NoneConnection from './Connections/NoneConnection';
import PublicIcon from '@mui/icons-material/Public';

import {
    useParams
} from "react-router-dom";
import TmConnection from './Connections/TmConnection';
import PcConnection from './Connections/PcConnection';
import AutorenewIcon from '@mui/icons-material/Autorenew';
import { count } from 'console';

function LinearProgressWithLabel(props: LinearProgressProps & { value: number }) {
    return (
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Box sx={{ width: '100%', mr: 1 }}>
                <LinearProgress variant="determinate" {...props} />
            </Box>
            <Box sx={{ minWidth: 35 }}>
                <Typography variant="body2" color="text.secondary">{`${Math.round(
                    props.value,
                )}%`}</Typography>
            </Box>
        </Box>
    );
}

export default function CodeList(props: any) {

    let { id } = useParams();

    const navigate = useNavigate();
    const [expanded, setExpanded] = React.useState<string | false>('panel1');
    const [isLoading, setIsLoading] = React.useState(false);

    const [limits, setLimit1s] = React.useState<any[]>([]);

    const [limit, setLimit] = React.useState(-1);
    const handleChange =
        (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
            setExpanded(isExpanded ? panel : false);
        };

    var getStatusSymbol = (enabled: Boolean, confId: any, isLink: Boolean, internalId: any, code: string, ping = false, type: number) => {

        if (confId != 0) {
            return enabled ? <ComputerIcon style={{ color: 'green', paddingRight: 5 }}></ComputerIcon> : <ComputerIcon style={{ color: 'red', paddingRight: 5 }}></ComputerIcon>
        }

        if (isLink) {


            if (type == 41) {
                return <PublicIcon style={{ color: enabled ? 'green' : "red", paddingRight: 5 }}></PublicIcon>
            }
            else if (type == 42) {
                return <ComputerIcon style={{ color: enabled ? 'green' : "red", paddingRight: 5 }}></ComputerIcon>
            }
            else if (type == 44 || type == 442) {
                return <Typography style={{ color: enabled ? 'green' : "red", paddingRight: 5 }}>[XRay]</Typography>
            }
            else if (ping || internalId == "") {
                return <LinkIcon style={{ color: 'black', paddingRight: 5 }}></LinkIcon>

            }
            else {
                return enabled ? <LinkIcon style={{ color: 'green', paddingRight: 5 }}></LinkIcon> : <LinkIcon style={{ color: 'red', paddingRight: 5 }}></LinkIcon>
            }
        }

        if (code == "none") {
            return <QrCodeIcon style={{ color: 'black', paddingRight: 5 }}></QrCodeIcon>
        }

        return enabled ? <QrCodeIcon style={{ color: 'green', paddingRight: 5 }} ></QrCodeIcon> : <QrCodeIcon style={{ color: 'red', paddingRight: 5 }}></QrCodeIcon>
        // return enabled ? "🟢" : "🔴"

    }


    var getStatusName = (enabled: Boolean, id: number, name: string) => {

        if (name == "") {
            return "Подключение #" + id
        }
        else {
            return name
        }

        //  return enabled ? <QrCodeIcon style={{color:'green',  paddingRight:5}} ></QrCodeIcon> : <QrCodeIcon style={{color:'red', paddingRight:5}}></QrCodeIcon> 
        // return enabled ? "🟢" : "🔴"

    }


    const location = useLocation();

    var returnToNewCodeForm = () => {

        navigate("/new", { state: { userId: userId == "" ? id : userId, conn: conn } })

        /*  getUrl("/api/ext_create_outline?token=cbd47545441b7ab0d456b99ce38682d3&id=" + userId)
            
             .then(res => {
                
 
                 if (res.status == 401) {
                     props.setToken(false)
                     return;
 
                 }
 
                 window.location.reload();
             }, error => {
                 window.location.reload();
             }) */

        /*  onChangeProtocClick(id, onMulticonnection, userId, serverTypeId == 7 || serverTypeId == 40)  */
        /*   navigate("/new", { state: { info: props.info, conn: conn } }) */

        /*     navigate("/new", { state: { id: item.Id, name: item.Name?.String == "" ? "empty" : item.Name?.String } })  */
        /* { state: { id: item.Id, name: item.Name?.String == "" ? "empty" : item.Name?.String } }) } */
    }

    var onDeleteClick = (id: any, isMulti: any, user_id: any, isOutline: boolean) => {
        setCurrentConn(id)
        setOpen2(true)
    }






    const handleClientsDownload = async (response: any) => {


        var element = document.createElement('a');
        element.setAttribute('href', 'data:text/plain;charset=utf-8,' + encodeURIComponent(response));
        element.setAttribute('download', `your_file_name.txt`);

        element.style.display = 'none';
        document.body.appendChild(element);

        element.click();

        document.body.removeChild(element);
    };

    const BootstrapButton = styled(Button)({
        borderBottom: '1px solid !important',
        borderRadius: '0px',
        padding: '0px',

        marginBottom: '5px',
        color: '#ffc107',//props.tg.themeParams.text_color ?? '#1E88E5',
        boxShadow: 'none',
        textTransform: 'none',
        fontSize: '0,85rem',
        backgroundColor: props.tg != undefined && props.tg.colorScheme == 'dark' ? '#323232' : 'white',
        borderColor: props.tg != undefined && props.tg.colorScheme == 'dark' ? '#323232' : 'white',

        '&:hover': {
            backgroundColor: props.tg != undefined && props.tg.colorScheme == 'dark' ? '#323232' : 'white',
            borderColor: props.tg != undefined && props.tg.colorScheme == 'dark' ? '#323232' : 'white',
            boxShadow: 'none',
        },
        '&:active': {
            boxShadow: 'none',
            backgroundColor: props.tg != undefined && props.tg.colorScheme == 'dark' ? '#323232' : 'white',
            borderColor: props.tg != undefined && props.tg.colorScheme == 'dark' ? '#323232' : 'white',
        },
        '&:focus': {
            boxShadow: '0 0 0 0.2rem rgba(0,123,255,.5)',
        },
    });

    const [isOpen, setIsOpen] = React.useState(false);

    const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);
    const [state, setState] = React.useState<any>({
        value: '',
        copied: false,
    })
    var onCopyClick = (event: any) => {
        setState({ value: 'dddsd', copied: false })
        setAnchorEl(event.currentTarget);
        setIsOpen(true)
    }

    const [open1, setOpen1] = React.useState(false);
    const [bonus, setBonus] = React.useState(0);
    const [currentConn, setCurrentConn] = React.useState(-2);
    const [isOutline, setIsOutlinen] = React.useState(false)

    const [isMulti, setIsMulti] = React.useState(false);

    const [isOk, setisOK] = React.useState(false);

    const [isLoadingButton, setIsLoadingButton] = React.useState(false);
    const [isRefreshing, setIsRefreshing] = React.useState(false);
    const [deletedCount, setDeletedCount] = React.useState("");
    const [isAllDeleted, setIsAllDeleted] = React.useState(false);

    const [userId, setUserIs] = React.useState("")
    //const current_user = 234051811
    const [text, setText] = React.useState(`Выбранное подключение будет удалено.
    Вместо него будет создано новое подключение от WhyPN на базе ShadowSocks.`);


    var onChangeProtocClick = (id: any, isMulti: any, user_id: any, isOutline: boolean) => {

        setIsRefreshing(true)
        getUrl("/api/refresh?" + "conn_id=" + id + "&user=" + user_id + "&change=" + false)

            .then(res => {
                /*  props.refresh() */


                if (res.status == 401) {
                    props.setToken(false)
                    return;

                }

                setIsRefreshing(false)
                navigate("/lk/" + user_id)
                window.location.reload()
            }, error => {
                navigate("/login")
            })
    }


    var onRefreshServerClick = (id: any, isMulti: any, user_id: any, isOutline: boolean) => {

        setIsRefreshing(true)
        getUrl("/api/refresh?" + "conn_id=" + id + "&user=" + user_id + "&change=" + true)

            .then(res => {
                /*  props.refresh() */


                if (res.status == 401) {
                    props.setToken(false)
                    return;

                }

                setIsRefreshing(false)
                navigate("/lk/" + user_id)
                window.location.reload()
            }, error => {
                navigate("/login")
            })
    }


    var onBonusClick = (user_id: any) => {


        setIsRefreshing(true)
        getUrl("/api/bonus?" + "user_id=" + user_id + "&summ=" + bonus)

            .then(res => {
                /*  props.refresh() */


                if (res.status == 401) {
                    props.setToken(false)
                    return;

                }

                setIsRefreshing(false)
                navigate("/lk/" + user_id)
                window.location.reload()
            }, error => {

                navigate("/login")
            })
    }


    var onRefreshServerClick = (id: any, isMulti: any, user_id: any, isOutline: boolean) => {

        setIsRefreshing(true)
        getUrl("/api/refresh?" + "conn_id=" + id + "&user=" + user_id + "&change=" + true)

            .then(res => {
                /*  props.refresh() */


                if (res.status == 401) {
                    props.setToken(false)
                    return;

                }

                setIsRefreshing(false)
                navigate("/lk/" + user_id)
                window.location.reload()
            }, error => {
                navigate("/login")
            })
    }



    var onStableRefreshServerClick = (id: any, isMulti: any, user_id: any, isOutline: boolean) => {

        setIsRefreshing(true)
        getUrl("/api/refresh_stable?" + "conn_id=" + id + "&user=" + user_id + "&change=" + true)

            .then(res => {
                /*  props.refresh() */


                if (res.status == 401) {
                    props.setToken(false)
                    return;

                }

                setIsRefreshing(false)
                navigate("/lk/" + user_id)
                window.location.reload()
            }, error => {
                navigate("/login")
            })
    }


    const [conn, setConn] = React.useState([]);
    const [ip, setIp] = React.useState(null);

    const [userInfo, setUserInfo] = React.useState(null);
    const [userInfoBalance, setUserInfoBalance] = React.useState(null);
    /*  const [isLoading, setIsLoading] = React.useState(true); */
    React.useEffect(() => {


        var a = id;
        if (id != null && userId == "") {
            setIsLoading(true)
            getUrl("/api/connections_by_id?" + "id=" + id)
                //fetch("http://localhost:5010" + "/api/connections?id=" + (props.tg.initDataUnsafe.user != undefined ? props.tg.initDataUnsafe.user.id :    current_user       ))
                .then(res => res.json())
                .then(
                    (result) => {


                        var conns = result
                        setIsLoading(false)
                        setConn(result);

                        fetch(getUrl("/api/limit", props.tg) + "serverId=" + props.data[0].ServerId + "&internalId=" + props.data[0].InternalId.String?.toString())
                            //fetch("http://localhost:5010" + "/api/available")
                            .then(res => res.text())
                            .then(
                                (result: any) => {

                                    setLimit(Number(result))
                                    //setFromCountries(result);
                                },
                                (error) => {
                                    //setIsLoaded(true);
                                    //setError(error);
                                    navigate("/login")
                                }
                            )

                        /*  getUrl("/api/ip")
                             //fetch("http://localhost:5010/api/ip")
                             .then(res => res.json())
                             .then(
                                 (result) => {
                                     setIsLoading(false)
                                     setIp(result)
                                 },
                                 (error) => {
        
                                 }
                             ) */
                    }, error => {
                        navigate("/login")
                    })

            getUrl("/api/user_info_by_id_balance?" + "id=" + id)
                //fetch("http://localhost:5010" + "/api/connections?id=" + (props.tg.initDataUnsafe.user != undefined ? props.tg.initDataUnsafe.user.id :    current_user       ))
                .then(res => res.json())
                .then(
                    (result) => {

                        setUserInfoBalance(result)
                        /* var conns = result
                        setIsLoading(false)
                        setConn(result); */


                        /*  getUrl("/api/ip")
                             //fetch("http://localhost:5010/api/ip")
                             .then(res => res.json())
                             .then(
                                 (result) => {
                                     setIsLoading(false)
                                     setIp(result)
                                 },
                                 (error) => {
        
                                 }
                             ) */
                    }, error => {
                        navigate("/login")
                    })

            getUrl("/api/user_info_by_id?" + "id=" + id)
                //fetch("http://localhost:5010" + "/api/connections?id=" + (props.tg.initDataUnsafe.user != undefined ? props.tg.initDataUnsafe.user.id :    current_user       ))
                .then(res => res.json())
                .then(
                    (result) => {

                        setUserInfo(result)
                        /* var conns = result
                        setIsLoading(false)
                        setConn(result); */


                        /*  getUrl("/api/ip")
                             //fetch("http://localhost:5010/api/ip")
                             .then(res => res.json())
                             .then(
                                 (result) => {
                                     setIsLoading(false)
                                     setIp(result)
                                 },
                                 (error) => {
        
                                 }
                             ) */
                    }, error => {
                        navigate("/login")
                    })
        }

    }, [])

    const [open2, setOpen2] = React.useState(false);

    var onDeleteOpenClick = (id: any, isMulti: any, user_id: any, isOutline: boolean) => {
        setIsOutlinen(isOutline)
        setCurrentConn(id)
        setIsMulti(isMulti)
        setOpen2(true)
    }

    var onDeleteClickClose = () => {
        setOpen2(false)
    }

    var onAllDeleteOpenApprove = async () => {

        setIsAllDeleted(true)
        for (var i = 0; i < checked1.length; i++) {
            var c = i + 1
            setDeletedCount("Удаляется " + c + "/" + checked1.length)
            var res = (conn as any).Connections.find((x: any) => x.Id == checked1[i])

            var isOutline = [7, 40, 41, 42, 4040].includes(res.ServerTypeId)
            var isXray = [44, 442].includes(res.ServerTypeId)


            if (isOutline) {
                await getUrl("/api/remove_outline?id=" + checked1[i])
                /*  fetch("http://localhost:5010" + '/api/remove_old?id=' + user_id) */


            } else if (isXray) {

                await getUrl("/api/remove_xray?id=" + checked1[i] + "&u=" + (userId != "" ? userId : id))
                //await getUrl("/api/remove_outline?id=")
            }



            else {
                /*    fetch("http://localhost:5010" + '/api/remove?id=' + id) */
                await getUrl("/api/remove?id=" + checked1[i])

            }


        }
        setIsAllDeleted(false)
        setChecked1([])
        navigate("/lk/" + userId)
    }



    var onDeleteOpenApproveXray = (isOutline: any, conn: any) => {


        getUrl("/api/remove_xray?id=" + conn + "&u=" + (userId != "" ? userId : id))
            /*  fetch("http://localhost:5010" + '/api/remove_old?id=' + user_id) */
            .then(res => {
                /*  props.refresh() */

                if (res.status == 401) {
                    props.setToken(false)
                    return;

                }

                window.location.reload();
            }, error => {
                navigate("/login")
            })
            ;



    }



    var onDeleteOpenApprove = (isOutline: any, conn: any) => {




        if (isOutline) {
            getUrl("/api/remove_outline?id=" + conn)
                /*  fetch("http://localhost:5010" + '/api/remove_old?id=' + user_id) */
                .then(res => {
                    /*  props.refresh() */

                    if (res.status == 401) {
                        props.setToken(false)
                        return;

                    }

                    window.location.reload();
                }, error => {
                    navigate("/login")
                })
                ;
        }


        else {
            /*    fetch("http://localhost:5010" + '/api/remove?id=' + id) */
            getUrl("/api/remove?id=" + conn)
                .then(res => {

                    if (res.status == 401) {
                        props.setToken(false)
                        return;

                    }
                    /*   props.refresh() */
                    window.location.reload();
                }, error => {
                    navigate("/login")
                }
                );
        }

        setOpen2(false)
    }

    var test = (ss: string) => {

        /*  const winUrl = URL.createObjectURL(
             new Blob([getHtmlToRedirect(ss)], { type: "text/html" })
         ); */

        /*      var windowSize = "width=" + window.innerWidth + ",height=" + window.innerHeight + ",scrollbars=no";
     
             window.open(getUrl("/api/openss", props.tg) + "id=" + ss) */

        /*   fetch(getUrl("/api/openss", props.tg) + "id=" + currentConn)
          .then(response => {
  
               
              let responseHtml = (response as any).data;
              console.log(responseHtml, "Monitoring");
             //open the new window and write your HTML to it
              var myWindow = window.open("", "response", "resizable=yes");
              (myWindow as any).document.write(responseHtml);
  
            
             // window.location.reload();
          }, error => {
             // window.location.reload();
          }
          ); */

        /*   window.open(
              getHtmlToRedirect(ss), '_blank', windowSize
          );
      */

        /* let newWindow = window.open();
        (newWindow as any).document.write( getHtmlToRedirect(ss),); */
        //window.open("https://whypn.com/testify02.html")
        /*  (child as any).document.write(getHtmlToRedirect(ss));
         (child as any).document.close(); */
        /*   var __html = require('./template.html');
          var template = { __html: __html }; */

        /*  return React.createElement("h1", {dangerouslySetInnerHTML: {__html: html}}) */

        /* const winHtml = `<!DOCTYPE html>
        <html>
            <head>
                <title>Window with Blob</title>
            </head>
            <body>
                <h1>Hello from the new window!</h1>
            </body>
        </html>`;
    
    const winUrl_ = URL.createObjectURL(
        new Blob([winHtml], { type: "text/html" })
    );
    
    
    const win = window.open(
       winUrl_,
        "win",
        `width=800,height=400,screenX=200,screenY=200`
    ); */
    }

    const handleClickOpen = () => {
        setIsLoadingButton(true)

        fetch(getUrl("/api/refresh", props.tg) + "conn_id=" + userId + "&user=" + (props.tg.initDataUnsafe.user != undefined ? props.tg.initDataUnsafe.user.id : userId))

            .then(res => {
                //  props.refresh()

                if (res.status == 402) {
                    redirect("/pay")
                }
                setIsLoadingButton(false)
                setisOK(true)
                setText("Подключение успешно обновлено")
                // setOpen1(false);



            }, error => {
                window.location.reload();
            })
            ;


    };

    const handleClose = () => {
        setOpen1(false);
        // window.location.reload();
    };


    const onChangeOpenApprove = () => {
        setOpen1(false);
        window.location.reload();
    };



    var getTariffPrcie = (conn: any) => {

        var price = 0


        price = conn.TariffPrice.Int64 == 0 ? 3 : conn.TariffPrice.Int64

        //  props.data == null ? 0 :(props.data as any)?.length * 3}

        return price;
    }


    const changeUserId = (event: React.ChangeEvent<HTMLInputElement>) => {
        setUserIs(event.target.value);
    }

    const onSearchClick = () => {
        setIsLoading(true)

        getUrl("/api/connections_by_id?" + "id=" + userId.trim())
            //fetch("http://localhost:5010" + "/api/connections?id=" + (props.tg.initDataUnsafe.user != undefined ? props.tg.initDataUnsafe.user.id :    current_user       ))
            .then(res => res.json())
            .then(
                (result) => {


                    var conns = result
                    setIsLoading(false)
                    setConn(result);


                    /*  getUrl("/api/ip")
                         //fetch("http://localhost:5010/api/ip")
                         .then(res => res.json())
                         .then(
                             (result) => {
                                 setIsLoading(false)
                                 setIp(result)
                             },
                             (error) => {
    
                             }
                         ) */
                }, error => {
                    navigate("/login")
                })

        getUrl("/api/user_info_by_id_balance?" + "id=" + userId.trim())
            //fetch("http://localhost:5010" + "/api/connections?id=" + (props.tg.initDataUnsafe.user != undefined ? props.tg.initDataUnsafe.user.id :    current_user       ))
            .then(res => res.json())
            .then(
                (result) => {

                    setUserInfoBalance(result)
                    /* var conns = result
                    setIsLoading(false)
                    setConn(result); */


                    /*  getUrl("/api/ip")
                         //fetch("http://localhost:5010/api/ip")
                         .then(res => res.json())
                         .then(
                             (result) => {
                                 setIsLoading(false)
                                 setIp(result)
                             },
                             (error) => {
    
                             }
                         ) */
                }, error => {
                    navigate("/login")
                })

        getUrl("/api/user_info_by_id?" + "id=" + userId.trim())
            //fetch("http://localhost:5010" + "/api/connections?id=" + (props.tg.initDataUnsafe.user != undefined ? props.tg.initDataUnsafe.user.id :    current_user       ))
            .then(res => res.json())
            .then(
                (result) => {

                    setUserInfo(result)
                    /* var conns = result
                    setIsLoading(false)
                    setConn(result); */


                    /*  getUrl("/api/ip")
                         //fetch("http://localhost:5010/api/ip")
                         .then(res => res.json())
                         .then(
                             (result) => {
                                 setIsLoading(false)
                                 setIp(result)
                             },
                             (error) => {
    
                             }
                         ) */
                }, error => {
                    navigate("/login")
                })
    }



    const handleChange1 = (id: any, enabled: string, userId: any) => {


        if (enabled) {
            getUrl("/api/disable?" + "id=" + id)
                //fetch("http://localhost:5010" + "/api/connections?id=" + (props.tg.initDataUnsafe.user != undefined ? props.tg.initDataUnsafe.user.id :    current_user       ))
                .then(res => {
                    navigate("/lk/" + userId)
                    window.location.reload()
                })

                .then(
                    (result) => {

                        navigate("/lk/" + userId)
                    }, error => {
                        navigate("/login")
                    })
        }

        else {
            getUrl("/api/enable?" + "id=" + id)
                //fetch("http://localhost:5010" + "/api/connections?id=" + (props.tg.initDataUnsafe.user != undefined ? props.tg.initDataUnsafe.user.id :    current_user       ))
                .then(res => {
                    navigate("/lk/" + userId)
                    window.location.reload()
                })
                .then(
                    (result) => {

                        navigate("/lk/" + userId)


                    }, error => {
                        navigate("/login")
                    })
        }
    };

    const handleSwitchXray = (id: any, enabled: string, userId: any) => {


        if (enabled) {
            getUrl("/api/disable?" + "id=" + id)
                //fetch("http://localhost:5010" + "/api/connections?id=" + (props.tg.initDataUnsafe.user != undefined ? props.tg.initDataUnsafe.user.id :    current_user       ))
                .then(res => {
                    navigate("/lk/" + userId)
                    window.location.reload()
                })

                .then(
                    (result) => {

                        navigate("/lk/" + userId)
                    }, error => {
                        navigate("/login")
                    })
        }

        else {
            getUrl("/api/enable?" + "id=" + id)
                //fetch("http://localhost:5010" + "/api/connections?id=" + (props.tg.initDataUnsafe.user != undefined ? props.tg.initDataUnsafe.user.id :    current_user       ))
                .then(res => {
                    navigate("/lk/" + userId)
                    window.location.reload()
                })
                .then(
                    (result) => {

                        navigate("/lk/" + userId)


                    }, error => {
                        navigate("/login")
                    })
        }
    };


    const onLimitShowClick = (id: any, serverId: any, internalId: any, user: any) => {


        getUrl("/api/xray_imit?" + "serverId=" + serverId + "&internalId=" + internalId + "&user=" + user)
            //fetch("http://localhost:5010" + "/api/connections?id=" + (props.tg.initDataUnsafe.user != undefined ? props.tg.initDataUnsafe.user.id :    current_user       ))
            .then(res => {

                return res.text()
            })
            .then(

                (result) => {
                    //var l = limits
                    //limits.push({ id: id, count: result })
                    setLimit1s(prevState => [...prevState,{ id: id, count: result }])
                    //navigate("/lk/" + userId)


                }, error => {
                    navigate("/login")
                })

    };

    const something = (event: any) => {

        if (event.keyCode === 13) {
            onSearchClick()
        }
    }

    const [checked1, setChecked1] = React.useState<any[]>([]);
    const [allChecked, setAllChecked] = React.useState(false);

    const handleChange_ = (event: React.ChangeEvent<HTMLInputElement>) => {

        var t: any[] = [];
        var c = checked1

        if (event.target.checked) {
            // c.push(event.target.value)


            (conn as any).Connections.forEach((x: any) => {
                t.push(x.Id);
            })
            setChecked1(t);
            setAllChecked(true)
        } else {
            setChecked1([]);
            setAllChecked(false)
        }
    };

    const changeBonusSumm = (event: React.ChangeEvent<HTMLInputElement>) => {

        setBonus(Number(event.target.value))

    };

    const handleChange_1 = (event: React.ChangeEvent<HTMLInputElement>) => {


        if (allChecked) {
            setAllChecked(false)
        }
        var c: any[] = []
        c.push(...checked1)
        var res = Number(event.target.value)
        if (event.target.checked) {
            c.push(res)
        } else {
            c = c.filter((x: any) => x != res)
        }
        setChecked1(c);
    };


    var handleKeyPress = (event: any) => {

        if (event.key === 'Enter') {
            setIsLoading(true)

            getUrl("/api/connections_by_id?" + "id=" + userId.trim())
                //fetch("http://localhost:5010" + "/api/connections?id=" + (props.tg.initDataUnsafe.user != undefined ? props.tg.initDataUnsafe.user.id :    current_user       ))
                .then(res => res.json())
                .then(
                    (result) => {


                        var conns = result
                        setIsLoading(false)
                        setConn(result);


                        /*  getUrl("/api/ip")
                             //fetch("http://localhost:5010/api/ip")
                             .then(res => res.json())
                             .then(
                                 (result) => {
                                     setIsLoading(false)
                                     setIp(result)
                                 },
                                 (error) => {
        
                                 }
                             ) */
                    }, error => {
                        navigate("/login")
                    })

            getUrl("/api/user_info_by_id_balance?" + "id=" + userId.trim())
                //fetch("http://localhost:5010" + "/api/connections?id=" + (props.tg.initDataUnsafe.user != undefined ? props.tg.initDataUnsafe.user.id :    current_user       ))
                .then(res => res.json())
                .then(
                    (result) => {

                        setUserInfoBalance(result)
                        /* var conns = result
                        setIsLoading(false)
                        setConn(result); */


                        /*  getUrl("/api/ip")
                             //fetch("http://localhost:5010/api/ip")
                             .then(res => res.json())
                             .then(
                                 (result) => {
                                     setIsLoading(false)
                                     setIp(result)
                                 },
                                 (error) => {
        
                                 }
                             ) */
                    }, error => {
                        navigate("/login")
                    })

            getUrl("/api/user_info_by_id?" + "id=" + userId.trim())
                //fetch("http://localhost:5010" + "/api/connections?id=" + (props.tg.initDataUnsafe.user != undefined ? props.tg.initDataUnsafe.user.id :    current_user       ))
                .then(res => res.json())
                .then(
                    (result) => {

                        setUserInfo(result)
                        /* var conns = result
                        setIsLoading(false)
                        setConn(result); */


                        /*  getUrl("/api/ip")
                             //fetch("http://localhost:5010/api/ip")
                             .then(res => res.json())
                             .then(
                                 (result) => {
                                     setIsLoading(false)
                                     setIp(result)
                                 },
                                 (error) => {
        
                                 }
                             ) */
                    }, error => {
                        navigate("/login")
                    })

        }
    }


    return (

        <Box style={{ marginTop: 10 }}>
            {/* <InputBase  ></InputBase> */}

            <Box sx={{ display: 'flex', alignItems: 'center', width: "100%" }}>
                <Paper

                    sx={{ display: 'flex', alignItems: 'center', width: "100%" }}
                >
                    <InputBase
                        onKeyUp={(e) => {
                            handleKeyPress(e)
                        }}
                        sx={{ ml: 1, flex: 1 }}
                        placeholder="ID или номер телефона"
                        //inputProps={{ 'aria-label': 'search google maps' }}
                        onChange={changeUserId}


                    />
                    <IconButton


                        type="button" sx={{ p: '10px' }} aria-label="search" onClick={onSearchClick} >
                        <SearchIcon />
                    </IconButton>
                </Paper>
            </Box>
            {/*  <Box sx={{ p: 1 }}>
                <Paper
                    component="form"
                    sx={{ display: 'flex', alignItems: 'center', width: "100%" }}
                >


                    <IconButton


                        type="button" sx={{ p: '10px' }} aria-label="search" onClick={onSearchClick} >
                        <SearchIcon />
                    </IconButton>
                  
                </Paper>

            </Box> */}


            {
                isRefreshing
                    ? <Box sx={{ width: '100%' }}>
                        <LinearProgress />
                    </Box>
                    : <></>
            }

            {
                isAllDeleted
                    ? <Box sx={{ width: '100%' }}>
                        <LinearProgress />
                        <Typography>{deletedCount}</Typography>
                    </Box>
                    : <></>
            }

            {userId == "" && id == null
                ? <></>

                : isLoading
                    ? <Box sx={{ display: 'flex', justifyContent: 'center', pt: 2 }}>

                        <CircularProgress sx={{ display: 'flex', justifyContent: 'center', color: '#1E88E5' }} />
                    </Box>

                    :
                    <Box>


                        <AlertDialog dialog_open={open1} handleClickOpen={handleClickOpen} handleClose={handleClose} handleApprove={onChangeOpenApprove} isOk={isOk} text={text} isLoading={isLoadingButton} tg={props.tg}></AlertDialog>
                        {/*                             <DeleteDialog dialog_open={open2} handleClickOpen={onDeleteOpenClick} onDeleteClickClose={onDeleteClickClose} handleApprove={onDeleteOpenApprove()} isOk={isOk} isLoading={isLoadingButton} tg={props.tg}></DeleteDialog>
 */}                            {conn == null || conn.length <= 0

                            ? <div>{/* <NewUserPay tg={props.tg}></NewUserPay> */}</div>

                            :


                            <Box sx={{ mt: 3, p: 2 }} style={{ background: '#f9f9f9', borderRadius: '12px', boxShadow: 'none' }} className='test_1' >


                                <Box >
                                    <Grid container spacing={2} mb={2}>
                                        <Grid item xs={5}>
                                            <Typography fontWeight={700} textAlign={"start"}>Telegram ID : {userId != "" ? userId : id}</Typography>
                                            <Typography fontWeight={700} textAlign={"start"}>Номер телефона: {(userInfo as any)?.Phone != null ? (userInfo as any)?.Phone?.String == "" ? "Нет" : "Есть" : 'Нет'}</Typography>
                                            <Typography fontWeight={700} textAlign={"start"}>Баланс: {(userInfoBalance as any)[0]?.TotalNow} ₽</Typography>
                                        </Grid>
                                        {/*  <Grid item xs={7}>
                                            <Typography textAlign={'right'}>{(userInfo as any).Phone.String} MSK</Typography>
                                        </Grid> */}
                                    </Grid>

                                </Box>
                                <Box sx={{ mt: 2, mb: 2 }}>

                                    {/*  <FormControl fullWidth>
                                        <InputLabel id="demo-simple-select-label">Age</InputLabel>
                                        <Select
                                            labelId="demo-simple-select-label"
                                            id="demo-simple-select"
                                            //value={age}
                                            label="Age"
                                            //onChange={handleChange}
                                        >
                                            <MenuItem value={1}>Бонус</MenuItem>
                                            <MenuItem value={2}>Добавить баланс</MenuItem>
                                            <MenuItem value={3}>Убрать баланс</MenuItem>
                                        </Select>
                                    </FormControl> */}
                                    <Paper
                                        component="form"
                                        sx={{ p: '2px 4px', display: 'flex', alignItems: 'center', width: '100%' }}
                                    >
                                        {/*   <IconButton sx={{ p: '10px' }} aria-label="menu">
        <MenuIcon />
      </IconButton> */}
                                        <InputBase
                                            sx={{ ml: 1, flex: 1 }}
                                            placeholder="Размер бонуса"
                                            inputProps={{ 'aria-label': 'search google maps' }}
                                            type="number"
                                            onChange={changeBonusSumm}
                                        />
                                        <IconButton type="button" sx={{ p: '10px' }} aria-label="search" onClick={() => { onBonusClick(userId != null && userId != "" ? userId : id) }}>
                                            <AddIcon />
                                        </IconButton>
                                        {/*  <Divider sx={{ height: 28, m: 0.5 }} orientation="vertical" /> */}

                                    </Paper>

                                </Box>
                                <Box sx={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'space-between',
                                    mb: 1
                                }}>


                                    <Box style={{ display: 'flex', alignItems: 'center' }}>
                                        <FormControlLabel control={<Checkbox checked={allChecked} onChange={handleChange_} />} label={""} />

                                        <Typography style={{ color: 'black' }}>Подключения</Typography>


                                        <Box sx={{ ml: 2, p: 1, background: '#f9f2f2', borderRadius: '12px' }}>

                                            <IconButton
                                                className='pay_button'
                                                size="small"
                                                disabled={checked1.length < 1}
                                                style={{ borderRadius: '10px', textTransform: 'none', boxShadow: 'none' }}
                                                sx={{ textTransform: 'none', background: '#ffc107', color: 'white', borderRadius: 0, backgroundColor: '#ffc107' }}
                                                onClick={() => { onAllDeleteOpenApprove() }}
                                            >
                                                <DeleteIcon />
                                            </IconButton>

                                            <IconButton
                                                className='pay_button'
                                                size="small"
                                                disabled
                                                style={{ borderRadius: '10px', textTransform: 'none', boxShadow: 'none' }}
                                                sx={{ ml: 2, textTransform: 'none', background: '#ffc107', color: 'white', borderRadius: 0, backgroundColor: '#ffc107' }}
                                            >
                                                <RefreshIcon />
                                            </IconButton >


                                            <IconButton
                                                className='pay_button'
                                                size="small"
                                                disabled
                                                style={{ borderRadius: '10px', textTransform: 'none', boxShadow: 'none' }}
                                                sx={{ ml: 2, textTransform: 'none', background: '#ffc107', color: 'white', borderRadius: 0, backgroundColor: 'red' }}
                                            >
                                                <RefreshIcon />
                                            </IconButton >
                                        </Box>

                                    </Box>

                                    <Avatar
                                        style={{ zIndex: 200 }}
                                        onClick={returnToNewCodeForm}
                                        variant="rounded"
                                        sx={{

                                            backgroundColor: ' #ffc107;',
                                            color: 'white',
                                            width: 30, height: 30
                                        }}
                                    >
                                        <AddIcon fontSize="inherit" />
                                    </Avatar>
                                </Box>
                                < Divider style={{ color: '#edeef0' }}></Divider>


                                {

                                    conn == null
                                        ? <Box sx={{ mt: 1 }}>
                                            <Typography style={{ color: '#1E88E5', textAlign: 'center' }}>У вас ещё нет QR-кода :(</Typography>

                                        </Box>
                                        :

                                        (conn as any)?.Connections?.map((item: any, i: any) => {
                                            var a = Moment(item.Date)
                                            const formatDate = Moment(item.Date).utcOffset('GMT-00:00').format('DD.MM.YYYY HH:mm')
                                            console.log("Entered");
                                            // Return the element. Also pass key     
                                            return (
                                                <div style={{ display: 'flex', alignItems: 'flex-start' }}>
                                                    <FormControlLabel control={
                                                        <Checkbox
                                                            value={item.Id}
                                                            checked={checked1.includes(item.Id)}
                                                            onChange={handleChange_1} />} label="" /* label={item.Id} */ />
                                                    <Box>
                                                        <Accordion sx={{ p: 0 }} expanded={expanded === 'panel' + (i + 1)}/*  defaultExpanded={i === 0 ? true : false}   */ onChange={handleChange('panel' + (i + 1))} style={{ background: '#90caf900', position: 'unset', boxShadow: 'none', padding: '0px' }}
                                                            className="accordion_test"
                                                        >
                                                            <AccordionSummary
                                                                className="accordion_test"
                                                                expandIcon={<ExpandMoreIcon style={{ color: '#ffffff' }} />}
                                                                aria-controls="panel2bh-content"
                                                                id="panel2bh-header"
                                                            >
                                                                <div style={{ display: 'flex', alignItems: 'center', }}>



                                                                    <Typography sx={{ width: '33%', flexShrink: 0 }} style={{ width: '100%', display: 'flex', alignItems: 'center' }}>


                                                                        {getStatusSymbol(item.IsEnabled, item.ConfId?.Int64, [7, 40, 41, 42, 4040, 44, 442].includes(item.ServerTypeId), item.InternalId.String?.toString(), item.Code, item.Code == '-3', item.ServerTypeId)}

                                                                        {/*   <IconButton style={{ color: props.tg.themeParams.text_color ?? 'black' }} onClick={() => { navigate("/edit", { state: { id: item.Id, name: getStatusName(item.IsEnabled, item.Id, item.Name?.String) } }) }}>
                                                                    <Edit />
                                                                </IconButton> */}
                                                                        <Typography>{getStatusName(item.IsEnabled, item.Id, item.Name?.String)}</Typography>

                                                                    </Typography>

                                                                </div>
                                                            </AccordionSummary>








                                                            <AccordionDetails>

                                                                {/*  {item.ServerTypeId != 7 && item.ServerTypeId != 40
                                                                        ? <Alert sx={{ mb: 2 }} severity="error"><Typography>Подключение может быть заблокировано вашим оператором связи (провайдером). Для стабильной работы обновите подключение</Typography> <Link href="https://telegra.ph/WhyPN-dlya-Beeline-08-05" target="_blank" underline="hover" style={{ color: '' }}>Инструкции</Link></Alert>
                                                                        : <div></div>} */}

                                                                {
                                                                    [44, 442].includes(item.ServerTypeId)
                                                                        ? <Grid container spacing={2} mb={1}>
                                                                            <Grid item xs={12}>
                                                                                <Grid
                                                                                    container
                                                                                    direction="row"
                                                                                    justifyContent="start"
                                                                                    alignItems="center"


                                                                                >




                                                                                    <IconButton
                                                                                        className='pay_button'
                                                                                        size="small"

                                                                                        style={{ borderRadius: '10px', textTransform: 'none', boxShadow: 'none' }}
                                                                                        sx={{ textTransform: 'none', background: '#ffc107', color: 'white', borderRadius: 0, backgroundColor: '#ffc107' }}
                                                                                        onClick={() => { onDeleteOpenApproveXray(item.ServerTypeId == 7 || item.ServerTypeId == 40, item.Id) }}
                                                                                    >
                                                                                        <DeleteIcon />
                                                                                    </IconButton>
                                                                                    <Switch
                                                                                        checked={item.IsEnabled}
                                                                                        onChange={() => handleSwitchXray(item.Id, item.IsEnabled, userId != "" ? userId : id)}
                                                                                        color="primary"
                                                                                        name="checkedB"
                                                                                        inputProps={{ 'aria-label': 'primary checkbox' }}
                                                                                    />



                                                                                </Grid>


                                                                            </Grid>

                                                                        </Grid>
                                                                        :

                                                                        <Grid container spacing={2} mb={1}>
                                                                            <Grid item xs={12}>
                                                                                <Grid
                                                                                    container
                                                                                    direction="row"
                                                                                    justifyContent="start"
                                                                                    alignItems="center"


                                                                                >


                                                                                    {/*    <IconButton
                                                                                    className='pay_button'
                                                                                    size="small"

                                                                                    style={{ borderRadius: '10px', textTransform: 'none', boxShadow: 'none' }}
                                                                                    sx={{ mr: 1, textTransform: 'none', background: '#ffc107', color: 'white', borderRadius: 0, backgroundColor: '#ffc107' }}
                                                                                    onClick={() => { navigate("/edit", { state: { id: item.Id, name: item.Name?.String == "" ? "empty" : item.Name?.String } }) }}
                                                                                >
                                                                                    <EditIcon />
                                                                                </IconButton >
 */}

                                                                                    <IconButton
                                                                                        className='pay_button'
                                                                                        size="small"

                                                                                        style={{ borderRadius: '10px', textTransform: 'none', boxShadow: 'none' }}
                                                                                        sx={{ mr: 1, textTransform: 'none', background: '#ffc107', color: 'white', borderRadius: 0, backgroundColor: '#ffc107' }}
                                                                                        onClick={() => { onChangeProtocClick(item.Id, item.OnMulticonnection, userId != null ? userId : id, item.ServerTypeId == 7 || item.ServerTypeId == 40) }}
                                                                                    >
                                                                                        <RefreshIcon />
                                                                                    </IconButton >


                                                                                    <IconButton
                                                                                        className='pay_button'
                                                                                        size="small"

                                                                                        style={{ borderRadius: '10px', textTransform: 'none', boxShadow: 'none' }}
                                                                                        sx={{ mr: 1, textTransform: 'none', background: '#ffc107', color: 'white', borderRadius: 0, backgroundColor: 'red' }}
                                                                                        onClick={() => { onRefreshServerClick(item.Id, item.OnMulticonnection, userId != null ? userId : id, item.ServerTypeId == 7 || item.ServerTypeId == 40) }}
                                                                                    >
                                                                                        <RefreshIcon />
                                                                                    </IconButton >

                                                                                    <IconButton
                                                                                        className='pay_button'
                                                                                        size="small"

                                                                                        style={{ borderRadius: '10px', textTransform: 'none', boxShadow: 'none' }}
                                                                                        sx={{ mr: 1, textTransform: 'none', background: 'blue', color: 'white', borderRadius: 0, backgroundColor: 'purple' }}
                                                                                        onClick={() => { onStableRefreshServerClick(item.Id, item.OnMulticonnection, userId != null ? userId : id, item.ServerTypeId == 7 || item.ServerTypeId == 40) }}
                                                                                    >
                                                                                        <RefreshIcon />
                                                                                    </IconButton >



                                                                                    <IconButton
                                                                                        className='pay_button'
                                                                                        size="small"

                                                                                        style={{ borderRadius: '10px', textTransform: 'none', boxShadow: 'none' }}
                                                                                        sx={{ textTransform: 'none', background: '#ffc107', color: 'white', borderRadius: 0, backgroundColor: '#ffc107' }}
                                                                                        onClick={() => { onDeleteOpenApprove(item.ServerTypeId == 7 || item.ServerTypeId == 40, item.Id) }}
                                                                                    >
                                                                                        <DeleteIcon />
                                                                                    </IconButton>


                                                                                    <Switch
                                                                                        checked={item.IsEnabled}
                                                                                        onChange={() => handleChange1(item.Id, item.IsEnabled, userId != "" ? userId : id)}
                                                                                        color="primary"
                                                                                        name="checkedB"
                                                                                        inputProps={{ 'aria-label': 'primary checkbox' }}
                                                                                    />
                                                                                    {/* 



                                                                            <IconButton
                                                                                className='pay_button'
                                                                                size="small"

                                                                                style={{ borderRadius: '10px', textTransform: 'none', boxShadow: 'none' }}
                                                                                sx={{ textTransform: 'none', background: '#ffc107', color: 'white', borderRadius: 0, backgroundColor: '#ffc107' }}
                                                                                onClick={() => { onDeleteOpenApprove(item.ServerTypeId == 7 || item.ServerTypeId == 40, item.Id) }}
                                                                            >
                                                                                <DeleteIcon />
                                                                            </IconButton> */}

                                                                                </Grid>


                                                                            </Grid>

                                                                        </Grid>
                                                                }




                                                                {

                                                                    item.IsDesc
                                                                        ?


                                                                        <div>

                                                                            <Grid container spacing={2} mb={2}>
                                                                                <Grid item xs={5}>
                                                                                    <Typography textAlign={"start"}>ID</Typography>
                                                                                </Grid>
                                                                                <Grid item xs={7}>
                                                                                    <Typography textAlign={'right'}>{formatDate} MSK</Typography>
                                                                                </Grid>
                                                                            </Grid>





                                                                            <Grid container spacing={2} mb={2}>
                                                                                <Grid item xs={5}>
                                                                                    <Typography textAlign={"start"}>Дата создания</Typography>
                                                                                </Grid>
                                                                                <Grid item xs={7}>
                                                                                    <Typography textAlign={'right'}>{item.Id}</Typography>
                                                                                </Grid>
                                                                            </Grid>

                                                                            <Grid container spacing={2} mb={2}>
                                                                                <Grid item xs={5}>
                                                                                    <Typography textAlign={"start"}>Регион</Typography>
                                                                                </Grid>
                                                                                <Grid item xs={7}>
                                                                                    <Typography textAlign={'right'}><i className={"em em-flag-" + item.Flag?.String?.toString().toLowerCase()} aria-role="presentation" aria-label="Netherlands Flag"></i> {item?.City?.String}</Typography>
                                                                                </Grid>
                                                                            </Grid>

                                                                            <Grid container spacing={2} mb={2}>
                                                                                <Grid item xs={5}>
                                                                                    <Typography textAlign={"start"}>Сервер 1</Typography>
                                                                                </Grid>
                                                                                <Grid item xs={7} >
                                                                                    <Typography textAlign={'right'}>{item.Domain}</Typography>
                                                                                </Grid>
                                                                            </Grid>

                                                                            <Grid container spacing={2} mb={2}>
                                                                                <Grid item xs={5}>
                                                                                    <Typography textAlign={"start"}>Статус</Typography>
                                                                                </Grid>
                                                                                <Grid item xs={7}>
                                                                                    <Typography textAlign={'right'}>{item.IsEnabled ? "🟢 OK" : "🔴 Заблокировано"}</Typography>
                                                                                </Grid>
                                                                            </Grid>
                                                                            <Box
                                                                                display="flex"
                                                                                justifyContent="center"
                                                                                alignItems="center"
                                                                                sx={{
                                                                                    borderRadius: '12px'
                                                                                }}

                                                                            >

                                                                                <div style={{ display: 'flex', alignItems: 'center', flexDirection: 'column', background: '#1E88E5' }} onClick={() => { handleClientsDownload(item.File) }}>
                                                                                    <DownloadIcon style={{ fontSize: 50 }}></DownloadIcon>
                                                                                    <Typography>Файл конфигурации</Typography>

                                                                                </div>
                                                                            </Box>

                                                                        </div>
                                                                        :

                                                                        [7, 40, 4040].includes(item.ServerTypeId)
                                                                            ?

                                                                            <div>

                                                                                <Grid container spacing={2}>
                                                                                    <Grid item xs={5}>
                                                                                        <Typography textAlign={"start"}>Internal ID</Typography>
                                                                                    </Grid>
                                                                                    <Grid item xs={7}>
                                                                                        <Typography textAlign={'right'}>{item.InternalId.String?.toString()}</Typography>
                                                                                    </Grid>


                                                                                </Grid>


                                                                                <Box mb={2}>
                                                                                    <Typography color={"lightgray"} variant="body2" textAlign={"left"}>(Если поле пустое, то требуется обновить подключение)</Typography>
                                                                                </Box>



                                                                                <Grid container spacing={2} mb={2}>
                                                                                    <Grid item xs={5}>
                                                                                        <Typography textAlign={"start"}>ID</Typography>
                                                                                    </Grid>
                                                                                    <Grid item xs={7}>
                                                                                        <Typography textAlign={'right'}>{item.Id}</Typography>
                                                                                    </Grid>
                                                                                </Grid>

                                                                                <Grid container spacing={2} mb={2}>
                                                                                    <Grid item xs={5}>
                                                                                        <Typography textAlign={"start"}>Тариф</Typography>
                                                                                    </Grid>
                                                                                    <Grid item xs={7}>
                                                                                        <Typography textAlign={'right'}>{getTariffPrcie(item)} ₽/день</Typography>
                                                                                    </Grid>
                                                                                </Grid>

                                                                                <Grid container spacing={2} mb={2}>
                                                                                    <Grid item xs={5}>
                                                                                        <Typography textAlign={"start"}>Дата создания</Typography>
                                                                                    </Grid>


                                                                                    <Grid item xs={7}>

                                                                                        <Typography textAlign={'right'}>{formatDate} UTC</Typography>
                                                                                    </Grid>
                                                                                </Grid>


                                                                                <Grid container spacing={2} mb={2}>
                                                                                    <Grid item xs={5}>
                                                                                        <Typography textAlign={"start"}>Регион</Typography>
                                                                                    </Grid>
                                                                                    <Grid item xs={7}>
                                                                                        {
                                                                                            item.Flag?.String == ""
                                                                                                ? <Typography textAlign={'right'}> Регион вычислен автоматически</Typography>
                                                                                                : <Typography textAlign={'right'}><i className={"em em-flag-" + item.Flag?.String?.toString().toLowerCase()} aria-role="presentation" aria-label="Netherlands Flag"></i> {item?.City?.String}</Typography>
                                                                                        }

                                                                                    </Grid>
                                                                                </Grid>


                                                                                <Grid container spacing={2} mb={2}>
                                                                                    <Grid item xs={5}>
                                                                                        <Typography textAlign={"start"}>Сервер 2</Typography>
                                                                                    </Grid>
                                                                                    <Grid item xs={7} >
                                                                                        <Typography textAlign={'right'}>{item.Domain}</Typography>
                                                                                        <Typography variant='body2' textAlign={'right'}>пользователей: {item.Count}</Typography>
                                                                                    </Grid>
                                                                                </Grid>

                                                                                <Grid container spacing={2} mb={2}>
                                                                                    <Grid item xs={5}>
                                                                                        <Typography textAlign={"start"}>Статус</Typography>
                                                                                    </Grid>
                                                                                    <Grid item xs={7}>
                                                                                        <Typography textAlign={'right'}>{item.IsEnabled ? "🟢 OK" : "🔴 Заблокировано"}</Typography>
                                                                                    </Grid>
                                                                                </Grid>

                                                                                <Box
                                                                                    display="flex"
                                                                                    flexDirection={"column"}
                                                                                    sx={{
                                                                                        color: "purple",
                                                                                        borderRadius: '12px',
                                                                                        mb: 1
                                                                                    }}

                                                                                >
                                                                                    <Typography textAlign={'right'}>{item.Key.String}</Typography>
                                                                                </Box>
                                                                                <Box
                                                                                    display="flex"
                                                                                    flexDirection={"column"}
                                                                                    sx={{
                                                                                        borderRadius: '12px'
                                                                                    }}

                                                                                >

                                                                                    <Box onClick={() => test(item.Id)} bgcolor={'#EFEFEF'} style={{
                                                                                        backgroundColor: '#EFEFEF', borderRadius: '12px', display: 'flex',
                                                                                        justifyContent: 'space-between', alignItems: 'center'
                                                                                    }} p={2} className={props.tg != undefined && props.tg.colorScheme != 'light' ? '' : '_link_box'}>


                                                                                        <Grid container onClick={onCopyClick} style={{
                                                                                            background: '#EFEFEF'
                                                                                        }} >
                                                                                            <Grid item xs={10} style={{
                                                                                                background: '#EFEFEF'
                                                                                            }}>



                                                                                                <Typography style={{ color: '#1E88E5', textAlign: 'left', wordWrap: 'break-word' }}>
                                                                                                    {item.Code}
                                                                                                </Typography>



                                                                                            </Grid>
                                                                                            <Grid item xs={2} textAlign={"right"} style={{ color: '#1E88E5' }}>
                                                                                                <OpenInNewIcon></OpenInNewIcon>
                                                                                            </Grid>
                                                                                        </Grid>
                                                                                        {/* 
            
 */}
                                                                                    </Box>
                                                                                    {
                                                                                        !isOpen
                                                                                            ? <div></div>
                                                                                            : <Typography sx={{ textAlign: 'center', color: 'lightgreen' /*'dark' ? 'rgb(255 255 255 / 8%)' : 'white'  */ }}>Скопировано</Typography>
                                                                                    }
                                                                                </Box>

                                                                            </div>

                                                                            :
                                                                            [44, 442].includes(item.ServerTypeId)
                                                                                ?

                                                                                <div>
                                                                                    <Grid container spacing={2} mb={2}>
                                                                                        <Grid item xs={5}>
                                                                                            <Typography textAlign={"start"}>Трафик</Typography>
                                                                                        </Grid>
                                                                                        <Grid item xs={7}>
                                                                                            {

                                                                                                limits.some(x => {

                                                                                                    return x.id == item.Id
                                                                                                })
                                                                                                    ?
                                                                                                    <>

                                                                                                        <Typography textAlign={'right'}>{limits.find(x => {
                                                                                                            return x.id == item.Id
                                                                                                        }).count} / 1500Гб </Typography>
                                                                                                    </>
                                                                                                    :
                                                                                                    <>
                                                                                                        <Typography
                                                                                                            onClick={() => { onLimitShowClick(item.Id, item.ServerId, item.InternalId.String?.toString(), userId != null ? userId : id) }}
                                                                                                            style={{ textDecoration: 'underline', cursor: 'pointer' }}
                                                                                                            textAlign={'right'}>Показать лимит</Typography>
                                                                                                    </>

                                                                                            }


                                                                                            {/*                                                                                             <Typography textAlign={'right'}>{item.Limit} Mb / 1500 Mb</Typography>
 */}                                                                                        </Grid>


                                                                                    </Grid>



                                                                                    <Grid container spacing={2} mb={2}>
                                                                                        <Grid item xs={5}>
                                                                                            <Typography textAlign={"start"}>Internal ID</Typography>
                                                                                        </Grid>
                                                                                        <Grid item xs={7}>
                                                                                            <Typography textAlign={'right'}>{item.InternalId.String?.toString()}</Typography>
                                                                                        </Grid>


                                                                                    </Grid>



                                                                                    <Grid container spacing={2} mb={2}>
                                                                                        <Grid item xs={5}>
                                                                                            <Typography textAlign={"start"}>ID</Typography>
                                                                                        </Grid>
                                                                                        <Grid item xs={7}>
                                                                                            <Typography textAlign={'right'}>{item.Id}</Typography>
                                                                                        </Grid>
                                                                                    </Grid>

                                                                                    <Grid container spacing={2} mb={2}>
                                                                                        <Grid item xs={5}>
                                                                                            <Typography textAlign={"start"}>Тариф</Typography>
                                                                                        </Grid>
                                                                                        <Grid item xs={7}>
                                                                                            <Typography textAlign={'right'}>{getTariffPrcie(item)} ₽/день</Typography>
                                                                                        </Grid>
                                                                                    </Grid>

                                                                                    <Grid container spacing={2} mb={2}>
                                                                                        <Grid item xs={5}>
                                                                                            <Typography textAlign={"start"}>Дата создания</Typography>
                                                                                        </Grid>


                                                                                        <Grid item xs={7}>

                                                                                            <Typography textAlign={'right'}>{formatDate} UTC</Typography>
                                                                                        </Grid>
                                                                                    </Grid>


                                                                                    <Grid container spacing={2} mb={2}>
                                                                                        <Grid item xs={5}>
                                                                                            <Typography textAlign={"start"}>Регион</Typography>
                                                                                        </Grid>
                                                                                        <Grid item xs={7}>
                                                                                            {
                                                                                                item.Flag?.String == ""
                                                                                                    ? <Typography textAlign={'right'}> Регион вычислен автоматически</Typography>
                                                                                                    : <Typography textAlign={'right'}><i className={"em em-flag-" + item.Flag?.String?.toString().toLowerCase()} aria-role="presentation" aria-label="Netherlands Flag"></i> {item?.City?.String}</Typography>
                                                                                            }

                                                                                        </Grid>
                                                                                    </Grid>


                                                                                    <Grid container spacing={2} mb={2}>
                                                                                        <Grid item xs={5}>
                                                                                            <Typography textAlign={"start"}>Сервер 2</Typography>
                                                                                        </Grid>
                                                                                        <Grid item xs={7} >
                                                                                            <Typography textAlign={'right'}>{item.Domain}</Typography>
                                                                                            <Typography variant='body2' textAlign={'right'}>пользователей: {item.Count}</Typography>
                                                                                        </Grid>
                                                                                    </Grid>

                                                                                    <Grid container spacing={2} mb={2}>
                                                                                        <Grid item xs={5}>
                                                                                            <Typography textAlign={"start"}>Статус</Typography>
                                                                                        </Grid>
                                                                                        <Grid item xs={7}>
                                                                                            <Typography textAlign={'right'}>{item.IsEnabled ? "🟢 OK" : "🔴 Заблокировано"}</Typography>
                                                                                        </Grid>
                                                                                    </Grid>

                                                                                    <Box
                                                                                        display="flex"
                                                                                        flexDirection={"column"}
                                                                                        sx={{
                                                                                            color: "purple",
                                                                                            borderRadius: '12px',
                                                                                            mb: 1
                                                                                        }}

                                                                                    >
                                                                                        <Typography textAlign={'right'}>{item.Key.String}</Typography>
                                                                                    </Box>
                                                                                    <Box
                                                                                        display="flex"
                                                                                        flexDirection={"column"}
                                                                                        sx={{
                                                                                            borderRadius: '12px'
                                                                                        }}

                                                                                    >

                                                                                        <Box onClick={() => test(item.Id)} bgcolor={'#EFEFEF'} style={{
                                                                                            backgroundColor: '#EFEFEF', borderRadius: '12px', display: 'flex',
                                                                                            justifyContent: 'space-between', alignItems: 'center'
                                                                                        }} p={2} className={props.tg != undefined && props.tg.colorScheme != 'light' ? '' : '_link_box'}>


                                                                                            <Grid container onClick={onCopyClick} style={{
                                                                                                background: '#EFEFEF'
                                                                                            }} >
                                                                                                <Grid item xs={10} style={{
                                                                                                    background: '#EFEFEF'
                                                                                                }}>



                                                                                                    <Typography style={{ color: '#1E88E5', textAlign: 'left', wordWrap: 'break-word' }}>
                                                                                                        {item.Code}
                                                                                                    </Typography>



                                                                                                </Grid>
                                                                                                <Grid item xs={2} textAlign={"right"} style={{ color: '#1E88E5' }}>
                                                                                                    <OpenInNewIcon></OpenInNewIcon>
                                                                                                </Grid>
                                                                                            </Grid>
                                                                                            {/* 
            
 */}
                                                                                        </Box>
                                                                                        {
                                                                                            !isOpen
                                                                                                ? <div></div>
                                                                                                : <Typography sx={{ textAlign: 'center', color: 'lightgreen' /*'dark' ? 'rgb(255 255 255 / 8%)' : 'white'  */ }}>Скопировано</Typography>
                                                                                        }
                                                                                    </Box>

                                                                                </div>
                                                                                :
                                                                                item.Code != "none"
                                                                                    ?

                                                                                    item.ServerTypeId == 41
                                                                                        ? <TmConnection
                                                                                            item={item}
                                                                                            tg={props.tg}
                                                                                            data={props.data}
                                                                                            /*  onDeleteOpenClick={onTmDeleteOpenClick}
                                                                                             onUpdateLimitClick={onUpdateLimitOpenClick} */
                                                                                            setLoadingState={props.setLoadingState}
                                                                                            test={test}
                                                                                            limit={limit}
                                                                                        /*              onInstructionClick={onInstructionClick} */
                                                                                        >

                                                                                        </TmConnection>
                                                                                        :
                                                                                        item.ServerTypeId == 42
                                                                                            ? <PcConnection
                                                                                                item={item}
                                                                                                tg={props.tg}
                                                                                                data={props.data}
                                                                                                /*   onDeleteOpenClick={onTmDeleteOpenClick} */
                                                                                                setLoadingState={props.setLoadingState}
                                                                                                test={test}
                                                                                                /*   onUpdateLimitClick={onUpdateLimitOpenClick} */
                                                                                                limit={limit}
                                                                                            /*   onInstructionClick={onInstructionClick} */
                                                                                            >

                                                                                            </PcConnection>
                                                                                            :
                                                                                            <div>

                                                                                                <Grid container spacing={2} mb={2}>
                                                                                                    <Grid item xs={5}>
                                                                                                        <Typography textAlign={"start"}>ID</Typography>
                                                                                                    </Grid>
                                                                                                    <Grid item xs={7}>
                                                                                                        <Typography textAlign={'right'}>{item.Id}</Typography>
                                                                                                    </Grid>
                                                                                                </Grid>

                                                                                                <Grid container spacing={2} mb={2}>
                                                                                                    <Grid item xs={5}>
                                                                                                        <Typography textAlign={"start"}>Дата создания</Typography>
                                                                                                    </Grid>
                                                                                                    <Grid item xs={7}>
                                                                                                        <Typography textAlign={'right'}>{formatDate} UTC</Typography>
                                                                                                    </Grid>
                                                                                                </Grid>

                                                                                                <Grid container spacing={2} mb={2}>
                                                                                                    <Grid item xs={5}>
                                                                                                        <Typography textAlign={"start"}>Регион</Typography>
                                                                                                    </Grid>
                                                                                                    <Grid item xs={7}>
                                                                                                        <Typography textAlign={'right'}><i className={"em em-flag-" + item.Flag?.String?.toString().toLowerCase()} aria-role="presentation" aria-label="Netherlands Flag"></i> {item.City?.String}</Typography>
                                                                                                    </Grid>
                                                                                                </Grid>

                                                                                                <Grid container spacing={2} mb={2}>
                                                                                                    <Grid item xs={5}>
                                                                                                        <Typography textAlign={"start"}>Сервер 3</Typography>
                                                                                                    </Grid>
                                                                                                    <Grid item xs={7} >
                                                                                                        <Typography textAlign={'right'}>{item.Domain}</Typography>
                                                                                                    </Grid>
                                                                                                </Grid>

                                                                                                <Grid container spacing={2} mb={2}>
                                                                                                    <Grid item xs={5}>
                                                                                                        <Typography textAlign={"start"}>Статус</Typography>
                                                                                                    </Grid>
                                                                                                    <Grid item xs={7}>
                                                                                                        <Typography textAlign={'right'}>{item.IsEnabled ? "🟢 OK" : "🔴 Заблокировано"}</Typography>
                                                                                                    </Grid>
                                                                                                </Grid>

                                                                                                <Box
                                                                                                    display="flex"
                                                                                                    justifyContent="center"
                                                                                                    alignItems="center"
                                                                                                    sx={{
                                                                                                        borderRadius: '12px'
                                                                                                    }}

                                                                                                >
                                                                                                    <img src={item.Code.replace('"', '').replace('"\n', '')} alt="Logo" />
                                                                                                </Box>
                                                                                                {
                                                                                                    ((conn as any).Connections.length > 1) ?
                                                                                                        props.isLoading1
                                                                                                            ?
                                                                                                            <div>{/* <LoadingButton

                                                                                        sx={{ mb: 1, mt: 2 }}
                                                                                        style={{
                                                                                            width: '100%', background: '#ffc107', boxShadow: 'none'
                                                                                        }} loading variant="outlined">
                                                                                        Загрузка
                                                                                    </LoadingButton>

                                                                                        <LoadingButton

                                                                                            sx={{ mb: 1, mt: 2 }}
                                                                                            style={{
                                                                                                width: '100%', background: '#C62828', boxShadow: 'none'
                                                                                            }} loading variant="outlined">
                                                                                            Загрузка
                                                                                        </LoadingButton> */}
                                                                                                            </div>
                                                                                                            :
                                                                                                            <div>
                                                                                                                {/*    <Button sx={{ mb: 1, mt: 2 }} style={{ width: '100%', background: '#ffc107', color: 'white', boxShadow: 'none' }} variant="contained" onClick={() => { props.setLoadingState(); onChangeProtocClick(item.Id, item.OnMulticonnection, props.tg.initDataUnsafe.user != undefined ? props.tg.initDataUnsafe.user.id : 671643425, item.ServerTypeId == 7) }}>Сменить протокол</Button>
                                                                                        <Button sx={{ mb: 1, mt: 2 }} style={{ width: '100%', background: '#C62828', color: 'white', boxShadow: 'none' }} variant="contained" onClick={() => { props.setLoadingState(); onDeleteClick(item.Id, item.OnMulticonnection, props.tg.initDataUnsafe.user != undefined ? props.tg.initDataUnsafe.user.id : 671643425, item.ServerTypeId == 7) }}>Удалить</Button> */}
                                                                                                            </div>
                                                                                                        : props.isLoading1
                                                                                                            ?
                                                                                                            <div>
                                                                                                                <LoadingButton

                                                                                                                    sx={{ mb: 1, mt: 2 }}
                                                                                                                    style={{
                                                                                                                        width: '100%', background: '#ffc107', boxShadow: 'none'
                                                                                                                    }} loading variant="outlined">
                                                                                                                    Загрузка
                                                                                                                </LoadingButton>
                                                                                                            </div>
                                                                                                            :
                                                                                                            <div>
                                                                                                                {/*       <Button sx={{ mb: 1, mt: 2 }} style={{ width: '100%', background: '#ffc107', color: 'white', boxShadow: 'none' }} variant="contained" onClick={() => { props.setLoadingState(); onChangeProtocClick(item.Id, item.OnMulticonnection, props.tg.initDataUnsafe.user != undefined ? props.tg.initDataUnsafe.user.id : 671643425, item.ServerTypeId == 7) }}>Сменить протокол</Button>
 */}
                                                                                                            </div>
                                                                                                }
                                                                                            </div>
                                                                                    :

                                                                                    <NoneConnection
                                                                                        onDeleteOpenClick={onDeleteOpenClick}
                                                                                        onChangeProtocClick={onChangeProtocClick}
                                                                                        setLoadingState={props.setLoadingState}
                                                                                        balance={props.balance}
                                                                                        item={item}
                                                                                        data={props.data}
                                                                                        tg={props.tg}

                                                                                    ></NoneConnection>
                                                                }
                                                            </AccordionDetails>
                                                        </Accordion>
                                                    </Box>
                                                    {
                                                        (conn as any).Connections.length - 1 == i
                                                            ? <div></div>
                                                            : < Divider style={{ color: '#edeef0' }} />
                                                    }

                                                </div>
                                            )
                                        })


                                }


                            </Box>

                        }
                    </Box>
            }

        </Box >
    );
}
