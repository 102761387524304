import * as React from 'react';
import { getUrl } from '../../auth/getUrl';
import { useState } from 'react';

import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
} from 'chart.js';
import TextField, { TextFieldProps } from '@mui/material/TextField';
import { Line } from 'react-chartjs-2';
import { Box, Button, Paper, Stack, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from "@mui/material";
import { format } from "date-fns";

import { LineChart } from '@mui/x-charts/LineChart';
import { Block } from "@mui/icons-material";

import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { Dayjs } from "dayjs";
import { useNavigate } from 'react-router-dom';



export function XrayInfo(props: any) {
    const navigate = useNavigate();
    const [payInfoTm, setPayInfoTm] = useState<any>({});
    const [payInfoTmData, setPayInfoTmData] = useState<any>({});
    React.useEffect(() => {



        getUrl(`/api/payment_info_xray`)
            .then(response => {
                debugger
                return response.json()
            })
            .then(data => {

                setPayInfoTm(data)
            }, error => {
                navigate("/login")
            }
            );




      /*   getUrl(`/api/payment_info_xray_data`)
            .then(response => {
                return response.json()
            })
            .then(data => {
                setPayInfoTmData(data)
            }, error => {
                navigate("/login")
            }
            ); */



    }, [])



    React.useEffect(() => {
        getUrl(`/api/payment_info_xray`)
            .then(response => {
                return response.json()
            })
            .then(data => {

                setPayInfoTm(data)
            }, error => {
                navigate("/login")
            }
            );





      /*   getUrl(`/api/payment_info_xray_data?dateStart=` + props.dateStart + "&dateEnd=" + props.dateEnd + "&counter=" + props.counter)
            .then(response => {
                return response.json()
            })
            .then(data => {
                setPayInfoTmData(data)
            }, error => {
                navigate("/login")
            }
            ); */



    }, [props.dateStart, props.dateEnd, props.counter])

    return (
        <Box>
            <hr />
            <Typography variant="subtitle2" style={{ display: "flex", paddingBottom: 10, color: '#1565c0' }} gutterBottom>
                Кол-во подключений <b> - {payInfoTm?.ConnCount}</b>
            </Typography>



          


            <Typography variant="subtitle2" style={{ display: "flex", paddingBottom: 10, color: '#1565c0' }} gutterBottom>
                Кол-во уникальных пользователей   <b> - {payInfoTm?.UserCount}</b>
            </Typography>


          {/*   {
                payInfoTmData != null && payInfoTmData.length > 0
                    ?
                    <LineChart
                        xAxis={[{
                            scaleType: 'point', data: payInfoTmData?.map((x: any) => {
                                var a = new Date(x.Data).toISOString().
                                    replace(/T/, ' ').      // replace T with a space
                                    replace(/\..+/, '')

                                
                                return a

                            })
                        }]}
                        series={[
                            {
                                data: payInfoTmData?.map((x: any) => x.UserCount),

                                color: '#5e35b1'
                            },
                        ]}

                        height={300}
                    />
                    : <></>
            } */}
        </Box>

    );
}
